/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            github
          }
        }
      }
    }
  `)

  // Uncomment the image tag to add an image, also replace
  // the image with your own picture.
  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      {/* <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      /> */}
      <p>
      <br/>
        {/* Written by <strong>{author}</strong>, documenting my programming journey. */}
        {` `}
        {/* <a href={`https://twitter.com/${social.twitter}`}>
          Follow me on Twitter
        </a>
         {` and `} */}
        {/* <a href={`https://github.com/${social.github}`}>
          Check out my Github.
        </a> */}
      <br/>
        This website may contain affiliate links, meaning I may receive a small commission for products purchased through these link at no extra cost to you.
      </p>
    </div>
  )
}

export default Bio
